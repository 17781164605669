var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('h3',{staticClass:"page-title d-flex justify-space-between align-center"},[_vm._v(" 櫃檯帳表管理 "),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.openDialog('create')}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" 建立帳表 ")],1)],1),_c('v-row',[_c('v-col',[_c('h4',[_vm._v("篩選條件")])])],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"6","sm":"4","md":"3"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.filterQuery.date,"label":"日期","outlined":"","readonly":"","dense":"","hide-details":"","clearable":""},on:{"click:clear":function($event){_vm.filterQuery.date = ''}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","locale":"zh-tw"},model:{value:(_vm.filterQuery.date),callback:function ($$v) {_vm.$set(_vm.filterQuery, "date", $$v)},expression:"filterQuery.date"}})],1)],1),_c('v-col',{attrs:{"cols":"6","sm":"4","md":"2"}},[_c('v-select',{attrs:{"label":"店點","outlined":"","dense":"","hide-details":"","clearable":"","items":_vm.branchOptions},model:{value:(_vm.filterQuery.branch),callback:function ($$v) {_vm.$set(_vm.filterQuery, "branch", $$v)},expression:"filterQuery.branch"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filterData,"mobile-breakpoint":0,"group-by":"date","item-key":"index","group-desc":true,"footer-props":{
          itemsPerPageText: '每頁幾筆',
          itemsPerPageOptions: [5],
        }},scopedSlots:_vm._u([{key:"item.actions",fn:function({ item }){return [_c('td',{staticClass:"pa-1"},[_c('v-btn',{staticClass:"mb-1 mb-lg-0 mr-lg-2",attrs:{"color":"success","small":""},on:{"click":function($event){return _vm.openDialog('update', item)}}},[_vm._v(" 編輯 ")]),_c('v-btn',{attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.remove(item)}}},[_vm._v("刪除")])],1)]}},{key:"item.payment",fn:function({ item }){return [_c('td',{staticClass:"pa-1"},[_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.getPayment(item)}}},[_vm._v(" 明細 ")])],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4","order":"first","order-md":"last"}},[_c('v-card',{},[_c('v-card-title',[_vm._v("收支明細")]),_c('v-card-text',[(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"size":50,"color":"primary","indeterminate":""}})],1):[_c('div',[_c('h4',{staticClass:"mb-3"},[_vm._v(" 日期： "+_vm._s(_vm.selectState ? _vm.dayjs(_vm.selectState.date).format("YYYY/MM/DD") : "---")+" ")]),_c('div',{staticClass:"mb-3"},[_c('h3',[_vm._v("收入")]),(
                    _vm.matchPayment.filter((item) => item.type === '收入')[0]
                  )?_c('v-data-table',{attrs:{"headers":_vm.paymentHeader,"items":_vm.matchPayment.filter((item) => item.type === '收入'),"mobile-breakpoint":0,"item-key":"index"}}):_c('div',[_vm._v("無資料")])],1),_c('div',[_c('h3',[_vm._v("支出")]),(
                    _vm.matchPayment.filter((item) => item.type === '支出')[0]
                  )?_c('v-data-table',{attrs:{"headers":_vm.paymentHeader,"items":_vm.matchPayment.filter((item) => item.type === '支出'),"mobile-breakpoint":0,"item-key":"index"}}):_c('div',[_vm._v("無資料")])],1)])]],2)],1)],1)],1),(_vm.dialog.open)?_c('DialogCreateIncomeStatement',{attrs:{"incomeStatement":_vm.payload,"dialog":_vm.dialog},on:{"load":_vm.load},model:{value:(_vm.dialog.open),callback:function ($$v) {_vm.$set(_vm.dialog, "open", $$v)},expression:"dialog.open"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }