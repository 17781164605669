<template>
  <v-container fluid>
    <h3 class="page-title d-flex justify-space-between align-center">
      櫃檯帳表管理

      <v-btn color="primary" @click="openDialog('create')">
        <v-icon>mdi-plus</v-icon>
        建立帳表
      </v-btn>
    </h3>
    <v-row>
      <v-col>
        <h4>篩選條件</h4>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="6" sm="4" md="3">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="true"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="filterQuery.date"
              v-bind="attrs"
              v-on="on"
              label="日期"
              outlined
              readonly
              dense
              hide-details
              clearable
              @click:clear="filterQuery.date = ''"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="filterQuery.date"
            no-title
            scrollable
            locale="zh-tw"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="6" sm="4" md="2">
        <v-select
          label="店點"
          v-model="filterQuery.branch"
          outlined
          dense
          hide-details
          clearable
          :items="branchOptions"
        >
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="8">
        <v-data-table
          :headers="headers"
          :items="filterData"
          :mobile-breakpoint="0"
          group-by="date"
          item-key="index"
          :group-desc="true"
          :footer-props="{
            itemsPerPageText: '每頁幾筆',
            itemsPerPageOptions: [5],
          }"
        >
          <template v-slot:item.actions="{ item }">
            <td class="pa-1">
              <v-btn
                color="success"
                small
                @click="openDialog('update', item)"
                class="mb-1 mb-lg-0 mr-lg-2"
              >
                編輯
              </v-btn>
              <v-btn color="error" small @click="remove(item)">刪除</v-btn>
            </td>
          </template>
          <template v-slot:item.payment="{ item }">
            <td class="pa-1">
              <v-btn color="primary" small @click="getPayment(item)">
                明細
              </v-btn>
            </td>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12" md="4" order="first" order-md="last">
        <v-card class="">
          <v-card-title>收支明細</v-card-title>
          <v-card-text>
            <div class="text-center" v-if="loading">
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </div>
            <template v-else>
              <div>
                <h4 class="mb-3">
                  日期：
                  {{
                    selectState
                      ? dayjs(selectState.date).format("YYYY/MM/DD")
                      : "---"
                  }}
                </h4>
                <div class="mb-3">
                  <h3>收入</h3>
                  <v-data-table
                    v-if="
                      matchPayment.filter((item) => item.type === '收入')[0]
                    "
                    :headers="paymentHeader"
                    :items="matchPayment.filter((item) => item.type === '收入')"
                    :mobile-breakpoint="0"
                    item-key="index"
                  >
                  </v-data-table>
                  <div v-else>無資料</div>
                </div>
                <div>
                  <h3>支出</h3>
                  <v-data-table
                    v-if="
                      matchPayment.filter((item) => item.type === '支出')[0]
                    "
                    :headers="paymentHeader"
                    :items="matchPayment.filter((item) => item.type === '支出')"
                    :mobile-breakpoint="0"
                    item-key="index"
                  >
                  </v-data-table>
                  <div v-else>無資料</div>
                </div>
              </div>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <DialogCreateIncomeStatement
      v-if="dialog.open"
      v-model="dialog.open"
      :incomeStatement="payload"
      :dialog="dialog"
      @load="load"
    />
  </v-container>
</template>

<script>
import util from "@/mixins/util";
import dayjs from "dayjs";
import _ from "lodash";
import { branchOptions } from "@/config/common.js";
import DialogCreateIncomeStatement from "@/components/dashboard/income-statement/DialogForm.vue";

export default {
  name: "IncomeStatement",
  mixins: [util],
  components: { DialogCreateIncomeStatement },
  data: () => ({
    dayjs,
    data: [],
    menu: false,
    filterQuery: {
      date: "",
      branch: "",
    },
    payload: {},
    dialog: { type: "create", open: false },
    branches: [],
    headers: [
      { text: "日期", value: "date", sortable: true },
      { text: "店點", value: "branch", sortable: true },
      { text: "零用金", value: "pettyCash", sortable: false },
      { text: "現金", value: "cash", sortable: false },
      { text: "支出", value: "expenditure", sortable: false },
      { text: "收入", value: "income", sortable: false },
      { text: "總計", value: "sum", sortable: false },
      { text: "備註", value: "comment", sortable: false },
      { text: "一番賞現金", value: "bandaiCash", sortable: false },
      { text: "一番賞匯款", value: "bandaiRemit", sortable: false },
      { text: "上次編輯者", value: "user", sortable: false, align: "center" },
      { text: "明細", value: "payment", sortable: false, align: "center" },
      { text: "操作", value: "actions", sortable: false, align: "center" },
    ],
    paymentHeader: [
      { text: "種類", value: "category", sortable: true },
      { text: "內容", value: "name", sortable: true },
      { text: "金額", value: "amount", sortable: false },
      { text: "來源", value: "source", sortable: false },
    ],
    selectState: null,
    matchPayment: [],
    loading: false,
  }),
  computed: {
    _: () => _,
    filterData() {
      let data = this.data;
      const { branch, date } = this.filterQuery;

      return data.filter((item) => {
        let result = true;
        if (branch) result = result && item.branch === branch;
        if (date) result = result && dayjs(item.date).isSame(date, "day");
        return result;
      });
    },
    branchOptions() {
      const branches = this.branches.map((branch) => {
        return { value: branch, text: branch + "店" };
      });
      return branches.length > 1
        ? [{ text: "全部", value: "" }, ...branches]
        : branches;
    },
  },
  async created() {
    this.resetPayload();
    await this.load();
  },
  methods: {
    async load() {
      this.$vloading.show();

      await this.getIncomeStatement();

      this.$vloading.hide();
    },
    async getIncomeStatement() {
      if (this.$store.state.user.角色 === "店長") {
        //店長支援友愛，但僅能開放友愛權限
        const { data } = await this.axios.get(`/incomeStatement`, {
          params: { branch: "友愛" },
        });
        this.data = data;
        this.branches = _.uniqBy(data, "branch").map((item) => item.branch);
      } else {
        const { data } = await this.axios.get(`/incomeStatement`);
        this.data = data;
        this.branches = _.uniqBy(data, "branch").map((item) => item.branch);
      }
    },
    async remove(item) {
      const confirm = await this.$dialog.confirm({
        text: `確認刪除 ${item.date} 此筆帳款？`,
      });
      if (!confirm) return;

      await this.axios.delete(`/incomeStatement/${item._id}`);
      this.load();
    },
    openDialog(type, payload) {
      if (payload) this.payload = _.cloneDeep(payload);
      else this.resetPayload();
      this.dialog.type = type;
      this.dialog.open = true;
    },
    resetPayload() {
      this.payload = {
        date: dayjs().format("YYYY-MM-DD"),
        pettyCash: 0,
        user: this.$store.state.user.帳號,
        cash: 0,
        expenditure: 0,
        income: 0,
        sum: 0,
        comment: "",
        bandaiCash: 0,
        bandaiRemit: 0,
      };
    },
    async getPayment(dailyStatement) {
      this.loading = true;
      this.selectState = dailyStatement;

      const { data } = await this.axios.get(
        `/payment/${dailyStatement.branch}/${dailyStatement.date}`
      );
      this.matchPayment = data;
      this.loading = false;
    },
  },
  watch: {},
};
</script>

<style scoped lang="scss"></style>
